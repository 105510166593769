import './workSection.css'
import Roll from 'react-reveal/Roll';
import Fade from 'react-reveal/Fade';
import {useTranslation, Trans} from "react-i18next";

function ITI() {
    const { t, i18n } = useTranslation();
    
    let imageSrc = 'images/ZG3D.jpg';
    let companyLogoSrc = 'images/iti.jpeg'
    let companyName = 'ITI - Instituto Tecnológico de Informática';
    let position = t("work.iti.position");
    let date = t("work.iti.date");
    let content = <Trans i18nKey="work.iti.tasks" components={{1: <br/>, 2: <a href='https://www.zerogravity3d.com' target={'_blank'} />}}/>

    return (
        <WorkSection imageSrc={imageSrc} companyLogoSrc={companyLogoSrc} companyName={companyName} position={position} date={date} content={content}/>
    )
}

function Dialapplet() {
    const { t, i18n } = useTranslation();

    let imageSrc = 'images/software-engineer.jpg';
    let companyLogoSrc = 'images/dialapplet.jpeg';
    let companyName = 'Dialapplet';
    let position = t("work.dial.position");
    let date = t("work.dial.date");
    let content = <Trans i18nKey="work.dial.tasks" components={{1: <br/>}}/>

    return (
        <WorkSection imageSrc={imageSrc} companyLogoSrc={companyLogoSrc} companyName={companyName} position={position} date={date} content={content}/>
    )
}

function WorkSection({imageSrc, companyLogoSrc, companyName, position, date, content}) {
    return (
        <div className='work-container'>
            <Roll left>
                <img src={imageSrc} style={{maxWidth: '80vw', width: '500px'}} />
            </Roll>
            <Fade right>
                <div>
                <div className="company">
                    <img src={companyLogoSrc} style={{width: '75px', height: '75px', margin: '5px'}}/>
                    <div>
                        <div style={{fontWeight: 'bold'}}>
                            {companyName}
                        </div>
                        <div>
                            {position}
                        </div>
                        <div style={{color: 'grey'}}>
                            {date}
                        </div>
                    </div>
                </div>
                <div style={{maxWidth: '525px', height: '100%'}}>
                    {content}
                </div>
                </div>
            </Fade>
        </div>
    )
}

export {ITI, Dialapplet};