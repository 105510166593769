import { useTranslation } from "react-i18next";
import { Tooltip } from 'antd';
import Fade from 'react-reveal/Fade';
import './skills.css'

const skills = [
    {name: 'Tensorflow', photo: 'images/skills/tensorflow.png'},
    {name: 'OpenCV', photo: 'images/skills/opencv.png'},
    {name: 'React', photo: 'images/skills/react.png'},
    {name: 'GNU/Linux', photo: 'images/skills/linux.png'},
    {name: 'Qt', photo: '/images/skills/qt.png'},
    {name: 'Git', photo: 'images/skills/git.png'},
    {name: 'Arduino', photo: 'images/skills/arduino.png'},
    {name: 'ThreeJS', photo: 'images/skills/threejs.png'},
    {name: 'Android Studio', photo: '/images/skills/androidstudio.png'},
    {name: 'C', photo: 'images/skills/c.png'},
    {name: 'C++', photo: 'images/skills/cplusplus.png'},
    {name: 'Python', photo: 'images/skills/python.png'},
    {name: 'Javascript', photo: 'images/skills/js.png'},
    {name: 'Java', photo: 'images/skills/java.png'},
    {name: 'HTML', photo: 'images/skills/html5.png'},
    {name: 'php', photo: 'images/skills/php.png'},
    {name: 'Docker', photo: 'images/skills/docker.png'}
]

function Skill({name, photoSrc}) {
    return (
        <Fade>
            <Tooltip title={name} placement={'bottom'}>
                <div className="skill-image-container">
                    <img src={photoSrc} className={'skill-image'} />
                </div>
            </Tooltip>
        </Fade>
    )
}

function Skills() {
    const t = useTranslation()[0];

    const skillsElements = []
    skills.forEach((skill) => skillsElements.push(<Skill name={skill.name} photoSrc={skill.photo} key={skill.name} />))

    return (
        <div className="section-container">
            <div className="section-title">
                {t('skills.title')}
            </div>
            <div className="skills-container">
                {skillsElements}
            </div>
        </div>
    )
}

export default Skills;