import { useTranslation } from "react-i18next";
import "./i18n";

import Main from './sections/main'
import WorkExperience from './sections/work'
import Projects from './sections/projects';
import Skills from './sections/skills';
import Social from "./sections/social";
import Studies from "./sections/studies";

function App() {
  const i18n = useTranslation()[1];

  const onClickChangeLanguage = (lang) => {
      return () => {
        i18n.changeLanguage(lang);
      }
  }

  let date = new Date();
  let year = date.getFullYear();

  return (
    <div>
      <div style={{position: 'absolute', top: '5px', right: '10px', display: 'flex', flexDirection: 'row', gap: '15px', zIndex: '10'}}>
        <div onClick={onClickChangeLanguage('en')} style={{color: i18n.language === 'en'? '': 'gray', cursor: 'pointer'}}>en</div>
        <div onClick={onClickChangeLanguage('es')} style={{color: i18n.language === 'es'? '': 'gray', cursor: 'pointer'}}>es</div>
      </div>
      <Main/>
      <WorkExperience/>
      <Studies/>
      <Projects/>
      <Skills/>
      <Social/>
      <div style={{width: '100%', textAlign: 'center', marginBottom: '0', color: 'gray', fontSize: '0.75em'}}>
        © {year} by Nicolas Garcia
      </div>
    </div>
  );
}

export default App;
