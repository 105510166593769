import './main.css'
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";

function Main() {
    const { t, i18n } = useTranslation();

    return (
        <Fade>
            <div className='center-main-container'>
                <div className='main-container'>
                    <img src="images/personal.jpg" className='personal-image'/>
                        <div className='main-text-container'>
                            <div>
                                {t('intro.hi')}
                            </div>
                            <div style={{marginTop: '25px'}}>
                                {t('intro.im')}
                            </div>
                            <div style={{marginTop: '25px', fontSize: '0.75em'}}>
                                {t('intro.whatami')}
                            </div>
                        </div>
                </div>
            </div>
        </Fade>
    )
}

export default Main;