import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

function Study({institution, institutionLogoSrc, description, link}) {
     
    return (
        <a href={link} target={'_blank'} style={{textDecoration: 'none', color: 'black'}} >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '15px', width: '600px', maxWidth: '80vw'}}>
                <Fade>
                    <img src={institutionLogoSrc} style={{height: '75px'}} />
                </Fade>
                <Fade right>
                    <div style={{}}>
                        <div style={{fontWeight: 'bold'}} >{institution}</div>
                        <div>{description}</div>
                    </div>
                </Fade>
            </div>
        </a>
    )
}

function Studies() {
    const { t, i18n } = useTranslation();

    return (
        <div className="section-container">
            <div className="section-title">
                {t('studies.title')}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '25px', gap: '25px'}}>
                <Study institution={t('studies.miarfid.uni')} institutionLogoSrc={'images/studies-logos/upv.jpeg'} description={t('studies.miarfid.descr')} link={'https://www.upv.es/titulaciones/MUIARFID/index.html'} />
                <Study institution={t('studies.ingf.uni')} institutionLogoSrc={'images/studies-logos/upv.jpeg'} description={t('studies.ingf.descr')} link={'https://www.upv.es/titulaciones/GII/'} />
            </div>
        </div>
    )
}

export default Studies;