import './work.css'
import {ITI, Dialapplet} from '../components/workSection';
import { useTranslation } from "react-i18next";

function WorkExperience() {
    const { t, i18n } = useTranslation();

    return(
        <div className="section-container">
            <div className="section-title">
                {t('work.workExperience')}
            </div>

            <ITI />
            <Dialapplet />
        </div>
    )
}

export default WorkExperience;