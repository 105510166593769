import './projects.css'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

function Project({title, description, link}) {
    return (
        <a style={{textDecoration: 'none', color: 'black', textAlign: 'left', width: '600px', maxWidth: '80vw'}} href={link} target={'_blank'}>
            <Fade>
                <div style={{fontWeight: 'bold'}}>{title}</div>
            </Fade>
            <Fade bottom>
                <div>{description}</div>
            </Fade>
            </a>
    )
}

function Projects() {
    const { t, i18n } = useTranslation();

    const ayllu = {
        title: 'Ayllu',
        description: t('projects.ayllu.description'),
        link: 'https://aylluarq.com/'
    }

    const collimator = {
        title: 'Collimator',
        description: t('projects.collimator.description'),
        link: 'https://github.com/NikoConn/collimator'
    }

    const IDRA = {
        title: 'IDRA',
        description: t('projects.idra.description'),
        link: 'https://play.google.com/store/apps/details?id=com.nicog.idra'
    }

    const projects = [ayllu, collimator, IDRA]
    const projectsComponents = []
    projects.forEach((project) => projectsComponents.push(<Project title={project.title} description={project.description} link={project.link} key={project.title} />))

    return(
        <div className="section-container">
            <div className="section-title">
                {t('projects.title')}
            </div>
            <div className='projects-container'>
                {projectsComponents}
            </div>
        </div>
    )
}

export default Projects;