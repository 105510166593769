import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: "en",   //default language
    fallbackLng: "en", //when specified language translations not present 
//then fallbacklang translations loaded.
    debug: true,
       backend: {
      /* translation file path */
      loadPath: "/assets/i18n/{{lng}}.json",
    },

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    }
  });

export default i18n;