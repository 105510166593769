import './projects.css'
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

function SocialIcon({imagePath, url}) {
    return (
        <a href={url} target={'_blank'} >
            <div style={{width: '75px', height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={imagePath} style={{width: '100%'}}/>
            </div>
        </a>
    )
}

function Social() {
    const { t, i18n } = useTranslation();

    return (
        <div className="section-container">
            <div className="section-title">
                {t('social.title')}
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '25px'}}>
                <Fade left>
                    <SocialIcon imagePath={'images/social/linkedin.png'} url={'https://www.linkedin.com/in/nicolás-garcía-sastre-500a5a1b9/'} />
                </Fade>
                <Fade right>
                    <SocialIcon imagePath={'images/social/github.png'} url={'https://github.com/NikoConn/'} />
                </Fade>
            </div>
        </div>
    )
}

export default Social;